<template>
	<AccordionSetsForm />
</template>

<script>
import AccordionSetsForm from '@/components/AccordionSetsForm.vue';
import { mapActions } from 'vuex';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ProductDetailAccordionSetCreate',
	components: {
		AccordionSetsForm,
	},
	data() {
		return {};
	},

	computed: {
	},

	created() {
		this.setBreadcrumbs([
			{
				title: 'Accordion Set',
				route: { name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET_LIST },
			},
		]);
	},

	methods: {
		...mapActions({
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),
	},
};
</script>
